// service worker
import { register } from 'register-service-worker'

// utilities
import { doLog } from '@/utils'

if (process.env.NODE_ENV === 'production') {
  register('service-worker.js', {
    ready () {
      doLog(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      doLog('Service worker has been registered.')

      // Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        registration.update()
      }, 1000 * 60 * 60) // hourly checks
    },
    cached () {
      doLog('Content has been cached for offline use.')
    },
    updatefound () {
      doLog('New content is downloading.')
    },
    updated (registration) {
      doLog('New content is available; please refresh.')
      // Add a custom event and dispatch it.
      // Set the event payload to the service worker registration object.
      document.dispatchEvent(
        new CustomEvent('serviceWorkerUpdated', { detail: registration })
      )
    },
    offline () {
      doLog('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      doLog(
        'Error during service worker registration:',
        'error',
        { error }
      )
    }
  })
}
