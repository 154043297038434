// vue
import { Ref, watch } from 'vue'

// pinia + stores
import { getActivePinia, Pinia, Store, storeToRefs } from 'pinia'
import { useStorageStore } from '@/store/storage'

// composables
import { useErrorHandler } from '@/composables/errorHandler'

// utilities
import { doLog } from '@/utils'

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>
}

export interface WatcherGenerator {
  propertyName: string
  valueToWatch: Ref
}

export function useStorage () {
  // pinia stores
  const pinia = getActivePinia() as ExtendedPinia
  const storageStore = useStorageStore()
  const { storageInstance } = storeToRefs(storageStore)

  // error handler
  const { doHandleError } = useErrorHandler()

  async function doClearStorage () {
    if (!storageInstance.value) {
      doLog('doClearStorage() :: Storage is not initialized', 'warn')
      return
    }

    try {
      return await storageInstance.value.clear()
    } catch (error) {
      doLog('doClearStorage() :: Error', 'error', { error })
      doHandleError(error as Error)
    }
  }

  function doGenerateStateWatchers (
    statePropertiesToWatch: WatcherGenerator[],
    piniaStoreName: string
  ) {
    if (!storageInstance.value) {
      doLog('doGenerateStateWatchers() :: Storage is not initialized', 'warn')
      return
    }

    for (const stateToWatch of statePropertiesToWatch) {
      const localStoragePropName =
        `${piniaStoreName}:${stateToWatch.propertyName}`

      if (stateToWatch.valueToWatch) {
        watch(
          stateToWatch.valueToWatch,
          async (statePropertyValue) => {
            await doSetStorageProperty(
              localStoragePropName,
              statePropertyValue
            )
          },
          { deep: typeof stateToWatch.valueToWatch.value === 'object' }
        )
      }
    }
    return
  }

  async function doGetStorageKeys () {
    if (!storageInstance.value) {
      doLog('doGetStorageKeys() :: Storage is not initialized', 'warn')
      return
    }

    try {
      return await storageInstance.value.keys()
    } catch (error) {
      doLog('doGetStorageKeys() :: Error', 'error', { error })
      doHandleError(error as Error)
    }
  }

  async function doGetStorageProperty (key: string) {
    if (!storageInstance.value) {
      doLog('doGetStorageProperty() :: Storage is not initialized', 'warn')
      return
    }

    try {
      return await storageInstance.value.get(key)
    } catch (error) {
      doLog('doGetStorageProperty() :: Error', 'error', { key, error })
      doHandleError(error as Error)
    }
  }

  async function doRemoveStorageProperty (key: string) {
    if (!storageInstance.value) {
      doLog('doRemoveStorageProperty() :: Storage is not initialized', 'warn')
      return
    }

    try {
      return await storageInstance.value.remove(key)
    } catch (error) {
      doLog('doRemoveStorageProperty() :: Error', 'error', { key, error })
      doHandleError(error as Error)
    }
  }

  async function doRemoveStoreFromStorage (piniaStoreName: string) {
    const localStorageKeys = await doGetStorageKeys() as string[]
    if (localStorageKeys) {
      const localStorageTranscriptKeys = localStorageKeys.filter((key) => {
        return key.includes(`${piniaStoreName}:`)
      })
      localStorageTranscriptKeys.forEach(async (key) => {
        await doRemoveStorageProperty(key)
      })
    }
  }

  async function doSetStorageProperty (key: string, value: unknown) {
    if (!storageInstance.value) {
      doLog('doSetStorageProperty() :: Storage is not initialized', 'warn')
      return
    }

    try {
      return await storageInstance.value.set(key, value)
    } catch (error) {
      doLog('doSetStorageProperty() :: Error', 'error', { key, value, error })
      doHandleError(error as Error)
    }
  }

  async function doSyncStorage () {
    if (!storageInstance.value) {
      doLog('doSyncStorage() :: Storage is not initialized', 'warn')
      return
    }

    const storageKeys = await doGetStorageKeys() as string[]
    for (const storageKey of storageKeys) {
      const valueToSync = await doGetStorageProperty(storageKey)
      const piniaStoreToSyncName = storageKey.split(':')[0]
      const stateToSync = storageKey.split(':')[1]
      const piniaStoreToSync = pinia._s.get(piniaStoreToSyncName)
      piniaStoreToSync?.$patch({ [stateToSync]: valueToSync })
    }
    return
  }

  return {
    doClearStorage,
    doGenerateStateWatchers,
    doGetStorageKeys,
    doGetStorageProperty,
    doRemoveStorageProperty,
    doRemoveStoreFromStorage,
    doSetStorageProperty,
    doSyncStorage
  }
}
