import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Drivers, Storage } from '@ionic/storage'
import { Nullable } from '@revolutionprep/types'

export const useStorageStore = defineStore(
  'storage',
  () => {
    // local state
    const storageInstance = ref<Nullable<Storage>>(null)

    // actions
    async function doInitStorage () {
      if (storageInstance.value) {
        return
      }
      // create storage instance
      const storage = new Storage({
        driverOrder: [Drivers.LocalStorage]
      })
      storageInstance.value = await storage.create()
    }

    return {
      doInitStorage,
      storageInstance
    }
  })
