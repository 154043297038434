import { $Fetch, FetchOptions } from 'ohmyfetch'
import { RepositoryInstance } from '../'

const createRepository =
  ($fetch: $Fetch) => (resource: string): RepositoryInstance => ({
    index (config: FetchOptions = {}) {
      return $fetch(resource, {
        ...config,
        credentials: 'include',
        method: 'GET'
      } as FetchOptions)
    },

    create (
      payload: Record<string, any> | undefined,
      config: FetchOptions = {}
    ) {
      return $fetch(resource, {
        ...config,
        body: payload,
        credentials: 'include',
        method: 'POST'
      } as FetchOptions)
    },

    show (id: number | string, config: FetchOptions = {}) {
      return $fetch(`${resource}/${id}`, {
        ...config,
        credentials: 'include',
        method: 'GET'
      })
    },

    update (
      id: number | string,
      payload: Record<string, any> | undefined,
      config = { method: 'PATCH' }
    ) {
      if (config.method !== 'PATCH') {
        return $fetch(`${resource}/${id}`, {
          ...config,
          body: payload,
          credentials: 'include',
          method: 'PUT'
        })
      }
      return $fetch(`${resource}/${id}`, {
        ...config,
        body: payload,
        credentials: 'include',
        method: 'PATCH'
      })
    },

    delete (id: number, config?: FetchOptions) {
      return $fetch(`${resource}/${id}`, {
        ...config,
        credentials: 'include',
        method: 'DELETE'
      })
    }
  })

export default createRepository
