import { defineStore } from 'pinia'
import { ref } from 'vue'

interface ToastData {
  isToastVisible?: boolean
  toastColor?: string
  toastDuration?: number
  toastIcon?: string
  toastMessage?: string
}

export const useToastStore = defineStore('toast', () => {
  const toastData = ref<ToastData>({
    isToastVisible: false,
    toastColor: 'secondary',
    toastDuration: 3000,
    toastIcon: '',
    toastMessage: ''
  })

  return {
    toastData
  }
})
