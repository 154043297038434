export interface MobileModalImageItem {
  imgSrc: string
  imgAlt: string
  label: string
}

export interface MobileModalVideoItem {
  heading: string
  iframeSrc: string
  iframeTitle: string
}

export const mobileModalImageItems: MobileModalImageItem[] = [
  {
    imgSrc: 'https://exams-cdn.revolutionprep.com/digital/demo/screenshots/Annotations.png',
    imgAlt: 'Annotations',
    label: 'Create annotations just like in the official exam.'
  },
  {
    imgSrc: 'https://exams-cdn.revolutionprep.com/digital/demo/screenshots/Crossout.png',
    imgAlt: 'Crossout answers',
    label: 'Cross out answer choices you think are wrong.'
  },
  {
    imgSrc: 'https://exams-cdn.revolutionprep.com/digital/demo/screenshots/Navigation.png',
    imgAlt: 'Navigation drawer',
    label: 'Navigate to questions you have marked for review.'
  },
  {
    imgSrc: 'https://exams-cdn.revolutionprep.com/digital/demo/screenshots/Calculator.png',
    imgAlt: 'Graphing calculator',
    label: `Get comfortable with the same graphing calculator used in the official
      Bluebook application.`
  },
  {
    imgSrc: 'https://exams-cdn.revolutionprep.com/digital/demo/screenshots/Student-Produced-Response.png',
    imgAlt: 'Student-produced responses',
    label: 'Practice new student-produced response questions.'
  }
]

export const mobileModalVideoItems: MobileModalVideoItem[] = [
  {
    heading: 'Reading and Writing Section',
    iframeSrc: 'https://player.vimeo.com/video/795771242?h=54f375666d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    iframeTitle: 'Digital Exam Demo - Reading and Writing Section'
  },
  {
    heading: 'Math Section',
    iframeSrc: 'https://player.vimeo.com/video/795771162?h=e20b5ad9aa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    iframeTitle: 'Digital Exam Demo - Math Section'
  }
]
