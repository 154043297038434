import { Nullable } from '@revolutionprep/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useErrorStore = defineStore('error', () => {
  const latestError = ref<Nullable<unknown>>(null)

  const latestErrorMessage = computed(() => {
    if (latestError.value instanceof Error) {
      return latestError.value.message
    } else if (latestError.value) {
      return String(latestError.value)
    }
    return ''
  })

  return {
    latestError,
    latestErrorMessage
  }
})
